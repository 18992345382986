/* Body resets
-------------------------------------------------- */

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: "freight-text-pro", Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 17px;
  letter-spacing: .005rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.45;
  overflow-y:scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
@media (min-width: 48em) {
  html {
    font-size: 18px;
  }
}

body {
  padding: 2rem 1.5rem;
  line-height: 1.45;
  color: rgba(0,0,0,0.8);
  background-color: #fff;
}

p {
  margin: 0 0 1rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: bold;
  line-height: 1.1;
  color: #333;
  letter-spacing: -.025rem;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.6rem;
}
h3 {
  font-size: 1.2rem;
}
p + h1,
p + h2,
p + h3,
ul + h1,
ul + h2,
ul + h3,
ol + h1,
ol + h2,
ol + h3 {
  margin-top: 1.5rem;
}

/* Emphasis */
em,
strong {
  padding-left: .1em;
  padding-right: .1em;
}

em {
  color: #666;
}

strong {
  font-weight: bold;
  color: #333;
}

/* Lists */
ul,
ol {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 1.5em;
}
dt {
  font-weight: bold;
}
dd {
  margin-bottom: .5rem;
}

.body li {
  margin-bottom: .25rem;
}
ul ul {
  margin-top: .25rem;
  margin-bottom: .5rem;
}

hr {
  width: 100px;
  height: 1px;
  margin: 2rem auto;
  background-color: #eee;
  border: 0;
}

abbr {
  background-color: #eee;
  display: inline-block;
  padding: 3px;
  font-size: 13px;
  font-weight: bold;
  color: #555;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  border-radius: 3px;
}

pre {
  display: block;
  margin: 0 0 1rem;
  font-size: .875rem;
  line-height: 1.4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}
pre code {
  padding: 0;
  color: inherit;
  background-color: transparent;
}
.highlight {
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  background-color: #f5f5f5;
  border-radius: .15rem;
}
.highlight pre {
  margin-bottom: 0;
  font-size: .875rem;
}

blockquote {
  padding: 0 0 0 1.5rem;
  margin: 0 2rem 1rem -2rem;
  color: #999;
  border-left: .5rem solid #e5e5e5;
}
blockquote p:last-child {
  margin-bottom: 0;
}

img {
  display: block;
  margin: 0 0 1rem;
  border-radius: .25rem;
}

/* Tables */
table {
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
}
td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}
tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}


/* Links
-------------------------------------------------- */

a {
  font-weight: inherit;
  line-height: inherit;
  color: #222;
  text-decoration: none;
  -webkit-transition: all .2s ease-in-out;
     -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
}
a:hover {
  color: #000;
}


/* Scaffolding
-------------------------------------------------- */

.container {
  max-width: 32rem;
  margin: 0 auto;
}

/* Masthead */
.masthead {
  margin-bottom: 0;
}
.masthead:after {
  display: block;
  content: "";
  width: 100px;
  margin: 3rem auto;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav li {
  display: inline-block;
}
.nav li + li {
  margin-left: .5rem;
}

@media (min-width: 34em) {
  .masthead {
    padding-top: 1rem;
    text-align: right;
  }
  .masthead-title {
    float: left;
  }
}

@media (max-width: 48em) {
  .masthead-title {
    margin-bottom: .25rem;
  }
}

.masthead-title .link-home:hover {
  color:#2cad4e !important;
}
.masthead-title .link-twitter:hover {
  color: #00aced !important;
}

.masthead-tagline {
  color: #ccc;
}

.qna,
.share,
.related,
.footer {
  text-align: center;
}

.qna a,
.share a,
.related a,
.footer a {
  border-bottom: 1px solid #ddd;
}

.qna a:hover,
.share a:hover,
.related a:hover,
.footer a:hover {
  border-bottom-color: #000;
}

.related ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

a[href^="#fn:"],
a[href^="#fnref:"] {
  display: inline-block;
  margin-left: .1rem;
  font-weight: bold;
}

.footnotes {
  margin-top: 2rem;
  font-size: 85%;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

/* Posts
-------------------------------------------------- */

/* List of posts
------------------------- */
.posts {
  margin-bottom: 4rem;
}
.posts li small {
  color: #999;
}
.posts li a:hover {
  color: #000;
  text-decoration: none;
}
/*.posts li a:hover small {
  color: #000;
}*/

/* Single post
------------------------- */
.post {
  position: relative;
  /*margin-bottom: 3rem;*/
}
/*.post:after {
  display: block;
  content: "";
  width: 100px;
  height: 1px;
  margin: 4rem auto;
  background-color: #eee;
}*/
.post img,
.post iframe {
  max-width: 100%;
}
.post img {
  margin-left: auto;
  margin-right: auto;
}
.post iframe {
  margin-bottom: 1rem;
}
.tweet-button {
  margin: .5rem 0 0;
}
.post-date {
  display: block;
  margin: -.5rem 0 .5rem;
  color: #ccc;
}

/*.page {
  padding-bottom: 2rem;
}*/
.page-title {
  margin-bottom: 2rem;
}
.page-body > p:first-of-type {
  font-size: 1.15rem;
}

.talk p {
  margin-top: 1rem;
}

/* Links in paragraphs get extra love */
.archive a,
.post-body a,
.page-body a {
  border-bottom: 1px solid #ddd;
}
.archive a:hover,
.post-body a:hover,
.page-body a:hover {
  border-bottom-color: #000;
}


/* Twitter tweet embeds */
iframe.twitter-tweet.twitter-tweet-rendered {
  margin: 1rem auto !important;
}


/* Pagination
-------------------------------------------------- */

.pagination {
  color: #ccc;
  text-align: center;
}
.pagination a {
  display: inline-block;
  padding: .5rem 1rem;
  margin: 0 .5rem;
}
.pagination a {
  border-radius: .25rem;
  border: 1px solid #fff;
}
.pagination a:hover {
  text-decoration: none;
  border: 1px solid #eee;
}

/* Profile Image
-------------------------------------------------- */

.about {
  position: relative;
  padding-bottom: 1.5rem;
}

.about .img-profile {
  height: auto;
  max-height: inherit;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0,0,0,0.3);
  border: 3px solid #fff;
  width: 25%;
}

@media (max-width: 48em) {
  .about .img-profile {
    width: 45%;
  }
}
